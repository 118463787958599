import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const baseUrl = 'https://pesok24.pro/api/';

export default new Vuex.Store({
  state: {
    services: [],
    events: [],
    adv: [],
    trainers: [],
    carousel: [],
    rating: null,
    website: null,
		courts: [],
		trainings: []
  },
  getters: {
    GET_SERVICES(state) {
      return state.services
    },
    GET_CAROUSEL(state) {
      return state.carousel
    },
    GET_EVENTS(state) {
      return state.events
    },
    GET_TRAINERS(state) {
      return state.trainers
    },
    GET_ADV(state) {
      return state.adv
    },
    GET_COURTS(state) {
      return state.courts
    },
    GET_TRAININGS(state) {
      return state.trainings
    },
    GET_RATING(state) {
      return state.rating
    },
    GET_WEBSITE(state) {
      return state.website
    },
  },
  mutations: {
    SET_CAROUSEL: (state, response) => {
      state.carousel = response
    },
    SET_SERVICES: (state, response) => {
      state.services = response
    },
    SET_EVENTS: (state, response) => {
      state.events = response
    },
    SET_ADV: (state, response) => {
      state.adv = response
    },
    SET_TRAINERS: (state, response) => {
      state.trainers = response
    },
    SET_RATING: (state, response) => {
      state.rating = response
    },
    SET_COURTS: (state, response) => {
      state.courts = response
    },
    SET_TRAININGS: (state, response) => {
      state.trainings = response
    },
    SET_WEBSITE: (state, response) => {
      state.website = response
    },
  },
  actions: {
    async GET_SERVICES_FROM_API(state) {
      actionHook(state,'services/', 'SET_SERVICES')
    },
    async GET_CAROUSEL_FROM_API(state) {
      actionHook(state,'carousel/', 'SET_CAROUSEL')
    },
    async GET_TRAINERS_FROM_API(state) {
      actionHook(state,'trainers/', 'SET_TRAINERS')
    },
    async GET_EVENTS_FROM_API(state) {
      actionHook(state,'events/', 'SET_EVENTS')
    },
    async GET_COURTS_FROM_API(state) {
      actionHook(state,'courts/', 'SET_COURTS')
    },
    async GET_RATING_FROM_API(state) {
      actionHook(state,'ratings/', 'SET_RATING')
    },
    async GET_TRAININGS_FROM_API(state) {
      actionHook(state,'trainings/', 'SET_TRAININGS')
    },
    async GET_ADV_FROM_API(state) {
      actionHook(state,'advantages/', 'SET_ADV')
    },
    async GET_WEBSITE_FROM_API(state) {
      actionHook(state,'website/', 'SET_WEBSITE')
    },
  },
})

function actionHook(state, url, setter) {
  return axios({
    url: baseUrl + url,
    method: 'get',
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
  .then((response) => {
    state.commit(setter, response.data);
    return response
  })
}
