<template>
<div class="coaches">
  <div class="coaches__container container">
    <div class="coaches__title">
      Тренеры
    </div>
    <div class="coaches__list">
      <div class="coaches__list-item"
           v-for="coach in coaches"
           :key="coach.name"
      >
        <div class="coaches__list-item-name">{{ coach.title }}</div>
        <div class="coaches__list-item-img">
          <img :src="coach.preview"/>
<!--          <img src="https://pesok24.pro/media/cache/47/01/47018a0613a9f4c7f1de917f211354d0.jpg"/>-->
          <span class="coaches__list-item-img-svg"></span>
        </div>
        
        <div class="coaches__list-item-description" v-html="coach.description"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import coachTest from '@/assets/img/coach.png';

export default {
  name: "SixthBlockCoaches",
  props: {
    coaches: {
      type: Array
    }
  },
  data() {
    return {
      coachTest
    }
  }
}
</script>

<style lang="scss">
.coaches {
  position: relative;
  margin-top: 20px;
  padding: 120px 0 100px;
  background: url('../assets/img/blackBg.png') center/cover no-repeat;
  
  @include below($mobile) {
    padding: 80px 0 40px;
  }
  
  &:before {
    position: absolute;
    content: '';
    top: -20px;
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #4961F9 0%, #FF4747 49.33%, #FFB72A 100.76%);
    z-index: 1;
  }
  
  &__container {
    @include below($mobile) {
      width: calc(100% - 15px);
    }
  }
  
  &__title {
    font-family: BOWLER, sans-serif;
    font-size: 48px;
    font-weight: 400;
    
    @include below($mobile) {
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
  }
  
  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    flex-wrap: wrap;
    
    @include below($mobile) {
      justify-content: flex-start;
    }
    
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 320px;
      margin-bottom: 80px;
      
      @include below($mobile) {
        width: 180px;
        padding: 0 0 0 10px;
        margin: 0 15px 40px 0;
      }
      
      &:nth-child(even) {
        & .coaches__list-item-img-svg {
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='158' height='260' viewBox='0 0 158 260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' y='6' width='146' height='248' rx='73' stroke='url(%23paint0_linear_43_1671)' stroke-width='12'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_43_1671' x1='79' y1='0' x2='79' y2='260' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2374449D'/%3E%3Cstop offset='1' stop-color='%231C43CE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          
          @include below($mobile) {
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='88' height='160' viewBox='0 0 88 160' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='83' height='155' rx='41.5' stroke='url(%23paint0_linear_82_4940)' stroke-width='5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_82_4940' x1='44' y1='3.87264e-10' x2='44.0118' y2='132.35' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2374449E'/%3E%3Cstop offset='1' stop-color='%231D43CE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
      }
      
      &:after {
        position: absolute;
        content: '';
        
        @include below($mobile) {
          //width: 88px;
          //height: 140px;
          //left: 0;
          //top: 0;
          //border-radius: 77.83px;
          //border: 10px solid #1C43CE;
        }
      }
      
      &-img {
        width: 260px;
        height: 260px;
        z-index: 1;
        
        @include below($mobile) {
          width: 160px;
          height: 160px;
        }
        
        @include below($mobileS) {
          width: 150px;
        }
        
        img {
          border-radius: 50%;
          position: relative;
          z-index: 1;
        }
        
        &-svg {
          position: absolute;
          content: '';
          width: 158px;
          height: 260px;
          right: 20px;
          background-repeat: repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='158' height='260' viewBox='0 0 158 260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' y='6' width='146' height='248' rx='73' stroke='url(%23paint0_linear_43_1666)' stroke-width='12'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_43_1666' x1='79' y1='6.29303e-10' x2='79.0174' y2='215.069' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFC73C'/%3E%3Cstop offset='1' stop-color='%23FD4546'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
          
          @include below($mobile) {
            right: 0px;
            width: 88px;
            height: 160px;
            background-repeat: repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='88' height='160' viewBox='0 0 88 160' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.5' y='2.5' width='83' height='155' rx='41.5' stroke='url(%23paint0_linear_82_4930)' stroke-width='5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_82_4930' x1='44' y1='3.87264e-10' x2='44.0118' y2='132.35' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFC73C'/%3E%3Cstop offset='1' stop-color='%23FD4546'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
          }
        }
      }
      
      &-name {
        max-width: 280px;
        margin: 20px 0;
        font-family: BOWLER, sans-serif;
        font-size: 20px;
        font-weight: 400;
        z-index: 1;
        
        @include below($mobile) {
          font-size: 14px;
        }
      }
      
      &-description {
        margin-top: 25px;
        margin-left: auto;
        z-index: 1;
        
        @include below($mobile) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>