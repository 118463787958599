<template>
<div id="pesok">
  <Header :website="GET_WEBSITE"/>
  <Preview
    :carousel="GET_CAROUSEL"
    @popupOpen="openPopup"
  />
  <FirstBlockPromo
    @scrollTo="scrollToId"
  />
  <SecondBlockServices
    :services="GET_SERVICES"
    @scrollTo="scrollToId"
    @popupOpen="openPopup"
  />
  <ThirdBlockOptions
    @scrollTo="scrollToId"
    :adv="GET_ADV"/>
  <FourthBlockPrices
    @popupOpen="openPopup"
    :courts="GET_COURTS"
    :groups="GET_TRAININGS"
    id="cost"/>
  <FifthBlockEvents
    id="events"
    :events="GET_EVENTS"
    @openPopup="openPopup"
  />
  <SixthBlockCoaches
    id="coaches"
    :coaches="GET_TRAINERS"/>
  <Contacts
    :site-info="GET_WEBSITE"
    id="contacts"/>
  <FooterSide
    :rating="GET_RATING"
    :website="GET_WEBSITE"
  />
  <MetaInfo />
  <Popup
    :url="popupUrl"
    :title="popupTitle"
    :text="popupText"
    :open="isPopupOpen"
    :btn="popupBtn"
    @closePopup="isPopupOpen = false"
  />
  <div id="scrollToTop"
       :class="[{'__show': isShowScrollTopBtn}]"
       @click="scrollToTop"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"
         clip-rule="evenodd" viewBox="0 0 64 64">
      <rect width="64" height="64" fill="none"/>
      <path stroke="#fff"
            d="M30.464,504.506C30.844,503.974 31.406,503.667 32,503.667C32.594,503.667 33.156,503.974 33.536,504.506L43.536,518.506C44.033,519.202 44.14,520.17 43.811,520.99C43.482,521.81 42.776,522.333 42,522.333L22,522.333C21.224,522.333 20.518,521.81 20.189,520.99C19.86,520.17 19.967,519.202 20.464,518.506L30.464,504.506ZM37.73,517.667L26.27,517.667L32,509.645L37.73,517.667Z"
            transform="matrix(-1 0 0 .85714 64 -409.714)"/>
      <path stroke="#fff"
            d="M666,284.333C655.882,284.333 647.667,276.118 647.667,266C647.667,255.882 655.882,247.667 666,247.667C676.118,247.667 684.333,255.882 684.333,266C684.333,273.976 679.229,280.77 672.111,283.289C671.243,283.596 670.789,284.549 671.095,285.416C671.402,286.283 672.356,286.738 673.223,286.431C681.634,283.454 687.667,275.426 687.667,266C687.667,254.042 677.958,244.333 666,244.333C654.042,244.333 644.333,254.042 644.333,266C644.333,277.958 654.042,287.667 666,287.667C666.92,287.667 667.667,286.92 667.667,286C667.667,285.08 666.92,284.333 666,284.333Z"
            transform="rotate(-45 68.919 1101.692) scale(1.2)"/>
    </svg>
  </div>
</div>
</template>
<script>
import Header from "@/components/Header";
import Preview from "@/components/Preview";
import FirstBlockPromo from "@/components/FirstBlockPromo";
import SecondBlockServices from "@/components/SecondBlockServices";
import ThirdBlockOptions from "@/components/ThirdBlockOptions";
import FourthBlockPrices from "@/components/FourthBlockPrices";
import FifthBlockEvents from "@/components/FifthBlockEvents";
import SixthBlockCoaches from "@/components/SixthBlockСoaches";
import Contacts from "@/components/Contacts";
import FooterSide from "@/components/FooterSide";

import {mapActions, mapGetters} from "vuex";
import Popup from "@/components/Popup";
import MetaInfo from "@/components/MetaInfo";

export default {
  name: 'PESOK',
  components: {
    MetaInfo,
    Popup,
    Contacts,
    SixthBlockCoaches,
    FifthBlockEvents,
    FourthBlockPrices,
    SecondBlockServices,
    FirstBlockPromo,
    Preview,
    Header,
    ThirdBlockOptions,
    FooterSide
  },
  data() {
    return {
      isShowScrollTopBtn: false,
      isPopupOpen: false,
      popupUrl: 'https://pesok24.pro/api/leads/',
      popupTitle: 'Арендовать корт',
      popupText: 'Подберём лучшее время для вас в течении 10 минут',
      popupBtn: 'Заказать',
      scrollPosition: '0'
    }
  },
  methods: {
    ...mapActions([
      'GET_SERVICES_FROM_API',
      'GET_CAROUSEL_FROM_API',
      'GET_EVENTS_FROM_API',
      'GET_ADV_FROM_API',
      'GET_TRAINERS_FROM_API',
      'GET_RATING_FROM_API',
      'GET_WEBSITE_FROM_API',
      'GET_COURTS_FROM_API',
      'GET_TRAININGS_FROM_API'
    ]),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    openPopup(mode) {
      this.isPopupOpen = true;
      if (mode === 'train') {
        this.popupUrl = 'https://pesok24.pro/api/leads/';
        this.popupBtn = 'Арендовать';
        this.popupTitle = 'Арендовать корт';
        this.popupText = 'Подберём лучшее время для вас в течении 10 минут';
      } else {
        this.popupUrl = 'https://probrand.team/create-lead/'
        this.popupBtn = 'Заказать';
        this.popupTitle = 'Заказать событие';
        this.popupText = 'Наш менеджер подберёт для вас подходящую программу';
      }
    },
    scrollToId(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'GET_EVENTS',
      'GET_SERVICES',
      'GET_CAROUSEL',
      'GET_ADV',
      'GET_TRAINERS',
      'GET_RATING',
      'GET_WEBSITE',
      'GET_COURTS',
      'GET_TRAININGS'
    ])
  },
  mounted() {
    this.GET_SERVICES_FROM_API()
    this.GET_CAROUSEL_FROM_API()
    this.GET_EVENTS_FROM_API()
    this.GET_ADV_FROM_API()
    this.GET_TRAINERS_FROM_API()
    this.GET_RATING_FROM_API()
    this.GET_WEBSITE_FROM_API()
    this.GET_COURTS_FROM_API()
    this.GET_TRAININGS_FROM_API()

    const self = this;
    window.addEventListener('scroll', function (e) {
      self.isShowScrollTopBtn = window.scrollY > 200;
    })
    const yc = document.createElement("script");
    yc.setAttribute(
      "src",
      "https://w956561.yclients.com/widgetJS"
    );
    document.head.appendChild(yc);
  },
  watch: {
    isPopupOpen: function () {
      if (this.isPopupOpen) {
        document.querySelector('body').classList.add('__no-scroll')
        if (window.innerWidth > '768') {
          document.querySelector('html').style.marginRight = '17px'
          document.querySelector('.header').style.transform = 'translateX(-8px)'
        }
      } else {
        document.querySelector('body').classList.remove('__no-scroll')
        document.querySelector('.header').style.transform = 'none'
        document.querySelector('html').style.marginRight = '0'
      }
    }
  }
}

</script>
<style>
#pesok {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 100vh;
}

#scrollToTop {
  position: fixed;
  bottom: 40px;
  right: -45px;
  width: 45px;
  height: 45px;
  z-index: 2;
  transition: .3s ease-in-out;
  cursor: pointer;

  &.__show {
    right: 20px;
  }
}
</style>
