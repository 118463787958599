<template>
  <div class="contacts__info-form">
    <template v-if="!success">
      <div class="contacts__info-form-title">
        Записаться На ПРОБНОЕ!
      </div>
      <div>
        <span>запишись на пробную тренировку со скидкой <strong>60%</strong></span>
      </div>
      <div class="contacts__info-form-field">
        <input
            v-model="form.name"
            type="text"
            placeholder="Ваше имя">
      </div>
      <div class="contacts__info-form-field">
        <input
            v-model="form.phone"
            type="text"
            placeholder="Телефон"
            v-mask="'+7 (###) ###-##-##'"
        >
      </div>
      <div :class="['contacts__info-form-submit',
                        {'__disabled': v$.$invalid}]"
           @click="sendForm"
      >
        <Btn
            text="Оставить заявку"
            mode="red"
        />
      </div>
      <div class="contacts__info-form-politics">
        Нажимая кнопку "Оставить заявку", вы соглашаетесь с <a href="/policy/" target="_blank">политикой
        конфиденциальности</a>
      </div>
    </template>
    <template v-if="sending && !success">
      <div class="contacts__info-form-sending">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="border-radius: 50%; overflow: hidden; display: block;" height="200px"
            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <defs>
            <mask id="ldio-m4re25r0t7-mask">
              <rect rx="50%" x="15" y="15" width="70" height="70" fill="white"></rect>
            </mask>
          </defs>
          <rect rx="50%" x="15" y="15" width="70" height="70" fill="#ffc83a"></rect>
          <g mask="url(#ldio-m4re25r0t7-mask)">
            <rect x="-100" y="-250" width="300" height="300" rx="90" ry="90" fill="#fe3e47"
                  opacity="0.4">
              <animateTransform attributeName="transform" type="rotate" dur="1s"
                                repeatCount="indefinite" values="0 50 -100;90 50 -100"
                                keyTimes="0;1"></animateTransform>
            </rect>

            <rect x="-100" y="-260" width="300" height="300" rx="95" ry="95" fill="#1b42ce"
                  opacity="0.4">
              <animateTransform attributeName="transform" type="rotate" dur="1.5151515151515151s"
                                repeatCount="indefinite" values="0 50 -110;90 50 -110"
                                keyTimes="0;1"></animateTransform>
            </rect>

            <rect x="-100" y="-270" width="300" height="300" rx="100" ry="100" fill="#1b42ce"
                  opacity="0.4">
              <animateTransform attributeName="transform" type="rotate" dur="3.0303030303030303s"
                                repeatCount="indefinite" values="0 50 -120;90 50 -120"
                                keyTimes="0;1"></animateTransform>
            </rect>
          </g>
        </svg>
      </div>
    </template>
    <template v-else-if="success">
      <div class="contacts__info-form-text">
        Ваша заявка отправлена, наш менеджер скоро с Вами свяжется
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import Btn from "@/components/blocks/Btn";
import {mask} from 'vue-the-mask'
import {useVuelidate} from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'
import axios from "axios";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  name: "ContactForm",
  props: {
    siteInfo: {}
  },
  components: {Btn},
  directives: {mask},
  data() {
    return {
      form: {
        name: '',
        phone: ''
      },
      success: false,
      sending: false
    }
  },
  methods: {
    sendForm() {
      let formBody = new FormData();
      this.sending = true;

      for (let k in this.form) {
        formBody.append(k, this.form[k])
      }

      formBody.append('lead_type', 'trial')

      if (window?.ym) window.ym(95371295,'reachGoal','1');

      axios({
        method: 'post',
        url: 'https://pesok24.pro/api/leads/',
        headers: {'Content-Type': 'application/json'},
        data: formBody,
      }).then(response => {
        this.success = true;
        this.sending = false;
      }).catch((e) => {
        this.sending = false;
        this.success = 'Ошибка при попытке отправить форму. Попробуйте позже'
      })
    },
    phoneFormatted(phone) {
      return phone.slice(0, 3) + ' ' + phone.slice(3, 5) + ' ' + phone.slice(5, 7)
    }
  },
  validations() {
    return {
      form: {
        phone: {
          required,
          minLength: minLength(18)
        },
        name: {
          required,
          minLength: minLength(2)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.contacts__info-form  {
    color: #000;
    position: relative;
    padding: 105px 0 65px;
    z-index: 1;

    @include below($mobile) {
      padding-top: 440px;
      margin: 0;
    }

    &:before {
      position: absolute;
      content: "";
      width: calc(180%);
      height: calc(100% - 105px);
      top: 50px;
      left: -100%;
      border: 20px solid #FFB72A;
      border-radius: 200px;
      z-index: -1;

      @include below($mobile) {
        height: calc(100% + 65px - 520px);
        top: inherit;
        bottom: 30px;
        width: calc(195%);
        border: 10px solid #FFB72A;
        border-radius: 100px;
      }
    }

    &-title {
      margin-bottom: 10px;
      font-family: BOWLER, sans-serif;
      font-size: 22px;
      font-weight: 400;

      @include below($mobile) {
        margin-bottom: 15px;
        font-size: 16px;
      }

      @include below($mobileS) {
        font-size: 14px;
      }
    }

    &-field {
      position: relative;
      margin-top: 20px;
      z-index: 1;

      @include below($mobile) {
        margin-top: 10px;
      }

      input {
        min-width: 470px;
        border-radius: 50px;
        border: 3px solid #CAD2D3 !important;

        @include below($mobile) {
          min-width: calc(80% - 40px);
        }

        //@include below($mobileS) {
        //    min
        //}
      }
    }

    &-submit {
      position: relative;
      margin-top: 30px;
      z-index: 1;

      &.__disabled {
        opacity: .7;
        pointer-events: none;
      }

      .btn {
        @include below($mobile) {
          padding: 20px 40px 16px !important;
          font-size: 14px;
        }

        @include below($mobileS) {
          padding: 20px 20px 16px !important;
          font-size: 14px;
        }
      }
    }

    &-politics {
      max-width: 400px;
      margin-top: 20px;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 300;
      z-index: 5;

      a {
        cursor: pointer;

        &:hover {
          color: #000;
          text-decoration: none;
        }
      }

      @include below($mobile) {
        padding: 0 10px;
        margin-right: 40px;
      }

      a {
        text-decoration: underline;
      }
    }

    &-sending {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      padding-right: 80px;
      width: calc(100% - 150px);
      height: calc(100% - 40px);
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      background: #fff;
      z-index: 1;
      opacity: .7;

      @include below($mobile) {
        width: calc(100% - 70px);
        height: calc(100% - 30px);
      }
    }

    &-text {
      max-width: 80%;
      font-size: 22px;
    }
}
</style>
