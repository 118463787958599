<template>
<div class="events" v-if="events">
  <div class="events__container container">
    <div class="events__left">
      <img ref="img" :src="currentPreview?.preview ? currentPreview?.preview : eventsImg"/>
    </div>
    <div class="events__right">
      <div class="events__title">События на песке</div>
      <div :class="['events__block',
                    {'__active': event.open}
                ]"
           v-for="(event,i) in eventsFormatted"
           :key="event.title">
        <div class="events__block-title"
             @click="openEvent(i)"
             v-html="event.title"
        ></div>
        <div :class="['events__block-text']" v-html="event.description">
        </div>
      </div>
      <btn
        @clickDef="$emit('openPopup')"
        class="events__btn"
        text="Узнать подробности"
        mode="red"
      />
    </div>
  </div>
</div>
</template>

<script>
import Btn from "@/components/blocks/Btn";
import eventsImg from '../assets/img/events.png';

export default {
  name: "FifthBlockEvents",
  components: {Btn},
  data() {
    return {
      eventsImg,
      eventsFormatted: this.formatted(this.events)
    }
  },
  props: {
    events: {}
  },
  watch: {
    events: function () {
      this.eventsFormatted = this.formatted(this.events)
    },
    currentPreview: function () {
      this.$refs.img.style.display = 'none';
      setTimeout(() => {
        this.$refs.img.style.display = 'block';
      }, 100)
    }
  },
  methods: {
    openEvent(i) {
      this.eventsFormatted.forEach(e => {
        e.open = false
      })
      this.eventsFormatted[i].open = true
    },
    formatted(list) {
      let newList = []
      this.events.forEach(e => {
        newList.push(Object.assign({open: false}, e))
      })
      
      if (newList.length) newList[0].open = true;
      
      return newList
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768
    },
    currentPreview() {
      return this.eventsFormatted.find(e => e.open)
    }
  }
}
</script>

<style lang="scss">
.events {
  position: relative;
  display: flex;
  background: #FFFFFF;
  
  @include below($mobile) {
    padding-bottom: 80px;
  }
  
  &__container {
    display: flex;
    padding: 120px 0 100px;
    color: #000;
    
    @include below($mobile) {
      flex-direction: column;
      padding: 80px 0;
    }
  }
  
  &__right {
    margin-left: 60px;
    max-width: 560px;
    
    @include below($mobile) {
      margin-left: 0;
      order: -1;
    }
  }
  
  &__left {
    width: 60%;
    margin: 0 0 0 -20px;
    
    img {
      animation: easeOpacity .3s ease-in-out;
    }
    
    @include below($mobile) {
      width: 100%;
      margin: 20px 0 0 -20px;
    }
  }
  
  &__title {
    margin: 80px 0 50px;
    font-family: BOWLER, sans-serif;
    font-size: 48px;
    font-weight: 400;
    
    @include below($mobile) {
      margin: 0 0 30px;
      font-size: 30px;
      line-height: 40px;
    }
  }
  
  &__block {
    
    &.__active {
      .events__block-title {
        color: #FE4546;
      }
      
      .events__block-text {
        max-height: 1000px;
      }
    }
    
    &-title {
      font-family: BOWLER, sans-serif;
      font-size: 22px;
      font-weight: 400;
      color: #000;
      cursor: pointer;
      transition: .2s ease-in-out;
      
      &:hover {
        color: #FE4546;
      }
      
      @include below($mobile) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    
    &-text {
      max-height: 0;
      margin: 15px 0 30px;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      color: #6F6F6F;
      overflow: hidden;
      transition: .4s ease-in-out;
      
      p {
        margin: 10px 0;
      }
      
      @include below($mobile) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  
  &__options {
    margin-top: 40px;
    
    @include below($mobile) {
      margin-top: 20px;
    }
    
    &-item {
      font-family: BOWLER, sans-serif;
      font-size: 22px;
      font-weight: 400;
      
      @include below($mobile) {
        font-size: 18px;
        line-height: 30px;
      }
      
      &:not(:first-child) {
        margin-top: 15px;
        
        @include below($mobile) {
          margin-top: 5px;
        }
      }
    }
  }
  
  &__btn {
    margin-top: 50px;
    
    @include below($mobile) {
      position: absolute;
      width: calc(100% - 30px);
      bottom: 80px;
    }
  }
  
  @keyframes easeOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>