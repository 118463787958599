import Vue from 'vue'
import Pesok from './Pesok'
import store from './store'
import VueTheMask from 'vue-the-mask';

Vue.config.productionTip = false

new Vue({
  store,
  VueTheMask,
  render: h => h(Pesok)
}).$mount('#app')