<template>
  <div class="carousel" ref="carousel">
    <div class="carousel__container" :style="containerStyle">
      <div v-for="(slide, index) in slides" :key="index" class="carousel__slide">
        <img
            :src="getImageUrl(slide)"
            :alt="`Slide ${index + 1}`"
            class="carousel__image"
            @error="handleImageError"
        >
      </div>
    </div>
    <div class="carousel__bullets">
      <button
          v-for="(slide, index) in slides"
          :key="index"
          class="carousel__bullet"
          :class="{ 'carousel__bullet--active': index === currentSlide }"
          @click="goToSlide(index)"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      required: true
    },
    delay: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      currentSlide: 0,
      intervalId: null
    }
  },
  computed: {
    containerStyle() {
      return {
        transform: `translateX(-${this.currentSlide * 100}%)`
      }
    },
    isMobile() {
      return window.innerWidth < 768
    }
  },
  mounted() {
    this.startAutoplay()
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount() {
    this.stopAutoplay()
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    startAutoplay() {
      this.intervalId = setInterval(() => {
        this.nextSlide()
      }, this.delay)
    },
    stopAutoplay() {
      clearInterval(this.intervalId)
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length
    },
    goToSlide(index) {
      this.currentSlide = index
      this.stopAutoplay()
      this.startAutoplay()
    },
    getImageUrl(slide) {
      const imageUrl = this.isMobile ? slide.mobile_preview : slide.desktop_preview;
      if (process.env.NODE_ENV === 'development') {
        const baseUrl = 'https://pesok24.pro/';
        return baseUrl + imageUrl;
      }
      return imageUrl;
    },
    handleImageError(e) {
      console.error('Image failed to load:', e.target.src)
      // Можно установить запасное изображение
      // e.target.src = '/path/to/fallback/image.jpg'
    },
    handleResize() {
      // Пересчитываем isMobile при изменении размера окна
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel__container {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.carousel__slide {
  flex: 0 0 100%;
  height: 100%;
}

.carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel__bullets {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
}

.carousel__bullet {
  width: 10px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel__bullet--active {
  background-color: #fff;
}
</style>
