<template>
  <div class="meta-info">
    <div class="meta-info__container container">
      <div class="meta-info__left">
        <p class="meta-info__item">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow">Privacy Policy</a> and
          <a href="https://policies.google.com/terms" target="_blank" rel="nofollow">Terms of Service</a> apply
        </p>
        <a href="/policy/" target="_blank" class="meta-info__item">Политика конфиденциальности</a>
        <p class="meta-info__item">Все права защищены</p>
      </div>
      <div class="meta-info__right">
        <p class="meta-info__item">© Pesok 2024</p>
        <a class="meta-info__item" target="_blank" rel="nofollow" href="https://vk.com/anita.lavrentyeva">designed by AniLav</a>
        <a class="meta-info__item" target="_blank" rel="nofollow" href="https://vk.com/foxfelix">created by Vadik</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetaInfo"
}
</script>

<style lang="scss" scoped>
.meta-info {
  display: flex;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  padding: 50px 0;
  border-top: 1px solid #fff;
  color: #7F7F7F;
  
  @include below($mobile) {
  
  }
  
  &__container {
    display: flex;
    justify-content: space-between;
    
    @include below($mobile) {
      flex-direction: column;
    }
  }
  
  &__right {
    @include below($mobile) {
      margin-top: 15px;
    }
  }
  
  &__item {
    display: block;
    padding: 4px 0;
    
    a {
      text-decoration: underline;
    }
  }
}
</style>