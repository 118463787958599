var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.website)?_c('div',{class:[
     'header',
    {'__fixed': _vm.isFixedHeader }
]},[_c('div',{staticClass:"header__container container"},[_c('a',{staticClass:"header__logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":_vm.img.logo}})]),_c('div',{staticClass:"header__socials"},[(_vm.website.vk)?_c('a',{staticClass:"header__socials-item",attrs:{"href":_vm.website.vk,"target":"_blank"}},[_c('img',{staticClass:"__v1",attrs:{"src":_vm.img.soc1}}),_c('img',{staticClass:"__v2",attrs:{"src":_vm.img.soc2v2}})]):_vm._e(),(_vm.website.tg)?_c('a',{staticClass:"header__socials-item",attrs:{"href":_vm.website.tg,"target":"_blank"}},[_c('img',{staticClass:"__v1",attrs:{"src":_vm.img.soc2}}),_c('img',{staticClass:"__v2",attrs:{"src":_vm.img.soc3v2}})]):_vm._e(),(_vm.website.inst)?_c('a',{staticClass:"header__socials-item",attrs:{"href":_vm.website.inst,"target":"_blank"}},[_c('img',{staticClass:"__v1",attrs:{"src":_vm.img.soc3}}),_c('img',{staticClass:"__v2",attrs:{"src":_vm.img.soc1v2}})]):_vm._e()]),_c('nav',{class:['header__nav',
            { '__open': _vm.isOpenMobileMenu }
        ]},[_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('about')}}},[_vm._v("О нас")]),_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('service')}}},[_vm._v("Услуги")]),_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('cost')}}},[_vm._v("Стоимость")]),_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('events')}}},[_vm._v("События")]),_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('coaches')}}},[_vm._v("Тренеры")]),_c('a',{staticClass:"header__nav-item",on:{"click":function($event){return _vm.scrollToId('contacts')}}},[_vm._v("Контакты")])]),_c('a',{staticClass:"header__phone",attrs:{"href":`tel:+7391${_vm.website.phone}`}},[_vm._v(" "+_vm._s(_vm.phoneFormatted(_vm.website.phone))+" ")]),_c('span',{class:[
            'header__mobile-menu-toggle',
            {'__close': _vm.isOpenMobileMenu}
        ],on:{"click":_vm.toggleMobileMenu}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }