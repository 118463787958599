<template>
    <div :class="[
         'header',
        {'__fixed': isFixedHeader }
    ]"
         v-if="website"
    >
        <div class="header__container container">
            <a href="/" class="header__logo">
                <img :src="img.logo">
            </a>
            <div class="header__socials">
                <a :href="website.vk" target="_blank" class="header__socials-item" v-if="website.vk">
                    <img :src="img.soc1" class="__v1"/>
                    <img :src="img.soc2v2" class="__v2"/>
                </a>
                <a :href="website.tg" target="_blank" class="header__socials-item" v-if="website.tg">
                    <img :src="img.soc2" class="__v1"/>
                    <img :src="img.soc3v2"  class="__v2"/>
                </a>
                <a :href="website.inst" target="_blank" class="header__socials-item" v-if="website.inst">
                    <img :src="img.soc3" class="__v1"/>
                    <img :src="img.soc1v2" class="__v2"/>
                </a>
            </div>
            <nav :class="['header__nav',
                { '__open': isOpenMobileMenu }
            ]">
                <a class="header__nav-item" @click="scrollToId('about')">О нас</a>
                <a class="header__nav-item" @click="scrollToId('service')">Услуги</a>
                <a class="header__nav-item" @click="scrollToId('cost')">Стоимость</a>
                <a class="header__nav-item" @click="scrollToId('events')">События</a>
                <a class="header__nav-item" @click="scrollToId('coaches')">Тренеры</a>
                <a class="header__nav-item" @click="scrollToId('contacts')">Контакты</a>
            </nav>
            <a :href="`tel:+7391${website.phone}`" class="header__phone">
                {{ phoneFormatted(website.phone) }}
            </a>
            <span :class="[
                'header__mobile-menu-toggle',
                {'__close': isOpenMobileMenu}
            ]"
                @click="toggleMobileMenu"
            ></span>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/img/logo.png'
import soc1 from '@/assets/img/vk.svg'
import soc2 from '@/assets/img/tg.svg'
import soc3 from '@/assets/img/inst.svg'
import soc1v2 from '@/assets/img/vkBlack.svg'
import soc2v2 from '@/assets/img/tgBlack.svg'
import soc3v2 from '@/assets/img/instBlack.svg'

export default {
    name: 'Header',
    props: {
        msg: String,
        website: {}
    },
    data() {
        return {
            img: {
                logo,
                soc1,
                soc2,
                soc3,
                soc1v2,
                soc2v2,
                soc3v2,
            },
            isOpenMobileMenu: false,
            isFixedHeader: window.scrollY > 200
        }
    },
    methods: {
        toggleMobileMenu() {
            this.isOpenMobileMenu = !this.isOpenMobileMenu;

            this.isOpenMobileMenu ?
                document.querySelector('body').classList.add('__no-scroll') :
                document.querySelector('body').classList.remove('__no-scroll')
        },
        scrollToId(id) {
            this.isOpenMobileMenu = false;
            document.querySelector('body').classList.remove('__no-scroll')

            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth"
                });
            }
        },
        phoneFormatted(phone) {
            return phone.slice(0, 3) + ' ' + phone.slice(3, 5) + ' ' + phone.slice(5, 7)
        }
    },
    mounted() {
        const self = this;

        window.addEventListener('scroll', function(e) {
            self.isFixedHeader = window.scrollY > 200;
            if (window.scrollY > 200) {
                document.getElementById('pesok').style.marginTop = '89px'
            }
            else {
                document.getElementById('pesok').style.marginTop = '0'
            }

        })
    }
}
</script>

<style lang="scss">
@import "src/assets/scss/consts";
@import "src/assets/scss/mixins";

.header {
    display: flex;

    &.__fixed {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        background: #000;
        z-index: 2;
        animation: easeOpen .4s ease-in-out;

        @include below($tablet) {
            .header__socials {
                display: none;
            }
        }

        .header__phone {
        }
    }

    &__container {
        display: flex;
        align-items: center;
        padding: 21px 0;

        @include below($mobile) {
            padding: 20px 10px;
        }

        @include below($mobileS) {
            padding: 20px 0;
        }
    }

    &__logo {
        cursor: pointer;
        margin-right: 10px;

        img {
            @include below($mobile) {
                width: 120px;
            }

            @include below($mobileS) {
                width: 100px;
            }
        }
    }

    &__socials {
        display: flex;
        margin-left: 70px;

        @include below($tablet) {
            position: absolute;
            top: 135px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            margin: 0;
        }

        &-item {
            height: 40px;
            width: 40px;
            transition: .15s ease-in-out;
            cursor: pointer;

            .__v1 {
                display: block;

                @include below($tablet) {
                    display: none;
                }
            }
            .__v2 {
                display: none;

                @include below($tablet) {
                    display: block;
                }
            }

            &:hover {
                transform: scale(1.15) rotateZ(10deg);
            }

            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        margin-left: 80px;

        &.__open {
            display: flex;
        }

        @include below($desktop) {
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            margin: 0 0 0;
            padding: 70px 0 0;
            z-index: 2;
            background: #010101fa;
        }

        &-item {
            position: relative;
            transition: .15s ease-in-out;
            cursor: pointer;
            z-index: 1;
            
            &.__only-mob {
                @include above(1240px) {
                    display: none;
                }
            }
            
            &:after {
                position: absolute;
                content: '';
                left: -6px;
                bottom: -3px;
                height: 30px;
                width: 0;
                background: #fff;
                transition: .15s ease-in;
                border-radius: 16px;
                z-index: -1;
                opacity: 0;
            }

            &:hover {
                color: #000;

                &:after {
                    width: calc(100% + 18px);
                    height: 30px;
                    opacity: 1;
                }
            }

            &:not(:first-child) {
                margin-left: 35px;

                @include below($desktop) {
                    margin: 10px 0 0;
                }
            }
        }
    }

    &__phone {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-top: 5px;
        color: #FFC83A;
        font-size: 20px;
        font-weight: 400;
        font-family: 'BOWLER', sans-serif;
        text-align: center;
        z-index: 0;

        @include below($desktop) {
            font-size: 18px
        }
    
        @include below($mobile) {
        }

        @include below($mobileS) {
            font-size: 16px;
            margin-left: 20px;
        }

        &:after {
            position: absolute;
            content: '';
            left: -6px;
            bottom: 2px;
            height: 30px;
            width: 0;
            background: #fff;
            transition: .15s ease-in;
            z-index: -1;
            border-radius: 16px;
            opacity: 0;
        }

        &:hover {
            color: #000000;
            font-weight: 700;

            &:after {
                width: calc(100% + 18px);
                height: 30px;
                opacity: 1;
            }
        }
    }

    &__mobile-menu-toggle {
        position: relative;
        display: none;
        margin-left: 40px;
        width: 30px;
        height: 20px;
        cursor: pointer;
        z-index: 2;
        background:  url("data:image/svg+xml,%3Csvg width='34' height='24' viewBox='0 0 34 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32 2L2 2' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M32 12L2 12' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M32 22L2 22' stroke='white' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E%0A") center/cover no-repeat;

        &.__close {
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48px' height='48px'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='3' d='M11.7,28.5L8,32.2c-0.8,0.8-0.8,2,0,2.8L13,40c0.8,0.8,2,0.8,2.8,0l8.2-8.2l8.2,8.2c0.8,0.8,2,0.8,2.8,0L40,35	c0.8-0.8,0.8-2,0-2.8L31.8,24l8.2-8.2c0.8-0.8,0.8-2,0-2.8l-2.6-2.6'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='3' d='M33.1,7.1L24,16.2L15.8,8c-0.8-0.8-2-0.8-2.8,0L8,13c-0.8,0.8-0.8,2,0,2.8l8.2,8.2'/%3E%3C/svg%3E");
        }

        @include below($desktop) {
            display: block;
        }

        @include below($mobileS) {
            width: 25px;
            height: 15px;
            margin-left: 20px;
        }
    }
}

@keyframes easeOpen {
    0% {
        top: -300px;
    }
    100% {
        top: 0
    }
}
</style>
