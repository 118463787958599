<template>
<div class="footer" v-if="website">
  <div class="footer__container container">
    <div class="footer__socials">
      <a href="/" class="footer__socials-logo">
        <img :src="img.logo"/>
      </a>
      <div class="footer__socials-list">
        <a
          class="footer__socials-list-item"
          v-if="website.vk"
          :href="website.vk"
          target="_blank"
        >
          <img :src="img.soc1"/>
        </a>
        <a class="footer__socials-list-item"
           v-if="website.tg"
           :href="website.tg"
           target="_blank"
        >
          <img :src="img.soc2"/>
        </a>
        <a class="footer__socials-list-item"
           v-if="website.inst"
           :href="website.inst"
           target="_blank"
        >
          <img :src="img.soc3"/>
        </a>
      </div>
    </div>
    <div class="footer__menu">
      <div class="footer__menu-title">
        МЕНЮ:
      </div>
      <div class="footer__menu-list">
        <a class="footer__menu-list-item" @click="scrollToId('about')">О НАС</a>
        <a class="footer__menu-list-item" @click="scrollToId('service')">УСЛУГИ</a>
        <a class="footer__menu-list-item" @click="scrollToId('cost')">СТОИМОСТЬ</a>
        <a class="footer__menu-list-item" @click="scrollToId('coaches')">ТРЕНЕРЫ</a>
        <a class="footer__menu-list-item" @click="scrollToId('events')">СОБЫТИЯ</a>
      </div>
    </div>
    <div class="footer__reviews" v-if="rating.yandex || rating.google">
      <div class="footer__reviews-title">
        Отзывы о нас:
      </div>
      <div class="footer__reviews-list" v-if="rating">
        <a :href="rating.yandex.link" class="footer__reviews-list-item __yandex" v-if="rating.yandex">
          <img :src="img.yandex" class="footer__reviews-list-item-img">
          <div class="footer__reviews-list-item-info">
            <div class="footer__reviews-list-item-info-el">
              Рейтинг: <strong>{{ rating.yandex.rating }}</strong>
            </div>
            <div class="footer__reviews-list-item-info-el">
              Отзывов: {{ rating.yandex.amount_reviews }}
            </div>
          </div>
          <img :src="img.yandexBg" class="footer__reviews-list-item-img-bg">
        </a>
        <a :href="rating['2gis'].link" class="footer__reviews-list-item __twoGis" v-if="rating['2gis']">
          <img :src="img.twoGisBg" class="footer__reviews-list-item-img">
          <div class="footer__reviews-list-item-info">
            <div class="footer__reviews-list-item-info-el">
              Рейтинг: <strong>{{ rating['2gis'].rating }}</strong>
            </div>
            <div class="footer__reviews-list-item-info-el">
              Отзывов: {{ rating['2gis'].amount_reviews }}
            </div>
          </div>
          <img :src="img.twoGisSmallLogo" class="footer__reviews-list-item-img-bg">
        </a>
        <a :href="rating.google.link" class="footer__reviews-list-item __google" v-if="rating.google">
          <img :src="img.google" class="footer__reviews-list-item-img">
          <div class="footer__reviews-list-item-info">
            <div class="footer__reviews-list-item-info-el">
              Рейтинг: <strong>{{ rating.google.rating }}</strong>
            </div>
            <div class="footer__reviews-list-item-info-el">
              Отзывов: {{ rating.google.amount_reviews }}
            </div>
          </div>
          <img :src="img.googleBg" class="footer__reviews-list-item-img-bg">
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import logo from "@/assets/img/logoFooter.png";
import soc1 from '@/assets/img/vk.svg'
import soc2 from '@/assets/img/tg.svg'
import soc3 from '@/assets/img/inst.svg'
import yandex from "@/assets/img/reviews/yandex.png";
import google from "@/assets/img/reviews/google.png";
import yandexBg from "@/assets/img/reviews/yandex_background.png";
import googleBg from "@/assets/img/reviews/google_background.png";
import twoGisBg from "@/assets/img/2gis-logo.webp";
import twoGisSmallLogo from "@/assets/img/2gisSmallLogo.png";

export default {
  name: "FooterSide",
  props: {
    rating: {},
    website: {}
  },
  data() {
    return {
      img: {
        logo,
        soc1,
        soc2,
        soc3,
        yandex,
        google,
        yandexBg,
        googleBg,
        twoGisBg,
        twoGisSmallLogo
      }
    }
  },
  methods: {
    scrollToId(id) {
      this.isOpenMobileMenu = false;
      document.querySelector('body').classList.remove('__no-scroll')
      
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }
    },
  }
}
</script>

<style lang="scss">
.footer {
  background: #000;
  
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 120px 0 100px;
    
    @include below($mobile) {
      flex-wrap: wrap;
      padding: 60px 0;
    }
  }
  
  &__socials {
    display: flex;
    flex-direction: column;
    
    @include below($mobile) {
      margin: 20px 20px 0 0;
    }
    
    &-logo {
      img {
        @include below($mobile) {
          width: 210px;
        }
      }
    }
    
    &-list {
      display: flex;
      justify-content: flex-start;
      margin-top: 50px;
      
      @include below($mobile) {
        margin-top: 30px;
      }
      
      &-item {
        height: 62px;
        width: 60px;
        transition: .15s ease-in-out;
        cursor: pointer;
        
        @include below($mobile) {
          height: 52px;
          width: 50px;
        }
        
        &:hover {
          transform: scale(1.15) rotateZ(10deg);
        }
        
        &:not(:first-child) {
          margin-left: 30px;
          
          @include below($mobile) {
            margin-left: 10px;
          }
        }
      }
    }
  }
  
  &__menu {
    display: flex;
    flex-direction: column;
    
    @include below($mobile) {
      margin: 15px 0 20px 0;
    }
    
    &-title {
      font-family: BOWLER, sans-serif;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px;
      
      @include below($mobile) {
        font-size: 16px;
      }
    }
    
    &-list {
      display: flex;
      flex-direction: column;
      
      &-item {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        transition: .2s ease-in-out;
        cursor: pointer;
        
        @include below($mobile) {
          font-size: 16px;
        }
        
        &:hover {
          color: #FFC83A
        }
      }
    }
  }
  
  &__reviews {
    font-family: BOWLER, sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    
    @include below($mobile) {
      width: 100%;
    }
    
    &-title {
      font-family: BOWLER, sans-serif;
      font-size: 20px;
      font-weight: 400;
      
      @include below($mobile) {
        font-size: 16px;
      }
    }
    
    &-list {
      display: flex;
      flex-direction: column;
      
      @include below($mobile) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      
      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 220px;
        height: 105px;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        margin-top: 20px;
        color: #000;
        font-size: 12px;
  
        &.__twoGis {
          .footer__reviews-list-item-img {
            width: 105px;
          }
        }
        
        @include below($mobile) {
          max-width: calc(50% - 10px);
          min-width: 140px;
          
          &:first-child {
            margin-right: 20px;
            
            @include below($mobile) {
              margin-right: 10px;
            }
          }
        }
        
        &-img {
          width: 140px;
          margin-bottom: auto;
          
          @include below($mobile) {
            width: 120px;
          }
          
          &-bg {
            position: absolute;
            top: 10px;
            right: 0;
            height: calc(100% - 20px);
            width: auto;
            
            @include below($mobile) {
              top: 50px;
              height: calc(100% - 60px);
            }
          }
        }
      }
    }
  }
}
</style>