<template>
    <a
        @click="$emit('clickDef')"
        v-html="text"
        :class="['btn', {'__red': mode === 'red'}]"
    >
    </a>
</template>

<script>
export default {
    name: "Btn",
    props: {
        text: {
            type: String,
            default: 'Кнопка',
        },
        mode: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/consts";
@import "src/assets/scss/mixins";

.btn {
	appearance: auto;
	text-rendering: auto;
	letter-spacing: normal;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	display: inline-block;
	align-items: flex-start;
	box-sizing: border-box;
	padding-block: 1px;
	padding-inline: 6px;
	border-image: initial;
  padding: 14px 60px 11px;
  font-family: 'BOWLER', sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 40px;
  background: #1C43CE;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: .15s ease-in background;
  border: 3px solid #ffffff00;

    @include below($desktop) {
        padding: 14px 40px 11px;
    }

    @include below($mobile) {
        padding: 14px 20px 11px;
        line-height: 15px;
        font-size: 14px;
    }

    &:hover {
        @include above($mobile) {
            background: #000619;
        }
    }

    &.__red {
        background: #FF4747;

        &:hover {
            @include above($mobile) {
                background: #000;
            }
        }
    }
}
</style>