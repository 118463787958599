<template>
    <div class="preview">
        <div class="preview__container container">
            <div class="preview__title">
                Центр пляжных<br>видов спорта
            </div>
            <img class="preview__logo" :src="logo" />
            <div class="preview__text">
                Первые крытые корты для пляжного волейбола
                в самом центре Красноярска!
            </div>
            <div class="contacts__info-form-wrapper">
              <ContactForm />
            </div>
            <!--
            <div class="preview__btns">
                <Btn
                    class="preview__btns-btn ms_booking"
                    data-url="https://n1079742.yclients.com/company/888903/activity/select"
                    :text="isMobile ? `Записаться на тренировку` : `Записаться на<br> тренировку`"
                    @click="$emit('popupOpen')"
                />
                <Btn
                    class="preview__btns-btn ms_booking"
                    data-url="https://n1079742.yclients.com/company/888903/personal/select-services"
                    :text="isMobile ? `Арендовать корт` : `Арендовать <br>корт`"
                    :mode="`red`"
                />
            </div>
          -->
        </div>
        <Swiper
            :slides="carousel"
            :delay="5000"
            class="preview__carousel"
        />
    </div>
</template>

<script>
import logo from '@/assets/img/logo.png'
import previewBg from '@/assets/img/preview.jpg'
import Btn from './blocks/Btn';
import ContactForm from './ContactForm.vue'
import Swiper from './Swiper.vue'

export default {
    name: "Preview",
    data() {
        return {
            previewBg,
            logo
        }
    },
    props: {
      carousel: []
    },
    components: {
        Btn,
        ContactForm,
        Swiper
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/consts";
@import "src/assets/scss/mixins";

.preview {
    .contacts__info-form-wrapper {
      display: flex;
      .contacts__info-form {
        margin-top: 30px;
        padding: 50px 20px;
        border-radius: 8px;
        background-color: #FFFFFF;
        &::before {
          display: none;

        }
      }
    }
    position: relative;
    background: url("../assets/img/preview.jpg") center/cover no-repeat;
    background-position: 80% 80%;
    margin-bottom: 20px;
    z-index: 1;

    &__carousel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    @include below($mobile) {
        margin-bottom: 0;
    }

    &:after {
        display: none;
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background: #00000044;
        width: 100%;
        height: 100%;
        z-index: 1;

        @include below($mobile) {
            display: block;
        }
    }

    &:before {
        position: absolute;
        content: '';
        bottom: -20px;
        width: 100%;
        height: 20px;
        background: linear-gradient(90deg, #4961F9 0%, #FF4747 49.33%, #FFB72A 100.76%);

        @include below($mobile) {
            bottom: -10px;
            width: 100%;
            height: 10px;
        }
    }

    &__container {
        position: relative;
        padding: 204px 0 175px;
        z-index: 2;

        @include below($mobile) {
            padding: 170px 30px 90px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include below($mobileS) {
            padding: 170px 0 90px;
        }
    }

    &__title {
        font-family: 'BOWLER', sans-serif;
        max-width: 560px;
        font-size: 50px;
        line-height: 60px;

        @include below($mobile) {
            font-family: BOWLER, sans-serif;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
        }
    }

    &__logo {
        margin-top: 30px;
        max-width: 180px;

        @include below($mobile) {
            margin-top: 30px;
        }
    }

    &__text {
        max-width: 511px;
        margin-top: 20px;
        font-family: Montserrat, sans-serif;
        font-size: 26px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;

        @include below($mobile) {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;

        }
    }

    &__btns {
        display: flex;
        margin-top: 60px;

        @include below($mobile) {
            flex-direction: column;
            width: 100%;
            margin-top: 105px;
        }

        &-btn:not(:first-child) {
            margin-left: 40px;

            @include below($mobile) {
                white-space: nowrap;
                margin: 20px 0 0;
            }
        }
    }
}
</style>
